<div class='w-full shift-offer-height max-h-screen flex flex-col'>
  <div class="offers_drawer" [ngClass]="{
        drawer_appear: isExpanded.drawer,
        drawer_disappear: !isExpanded.drawer
      }">
    <div class="offers_drawer_content">
      <div class="offers_drawer_header">
        <div>
          <span>Filters</span>
          <span (click)="handleVisivility('drawer', false)">
            <img src="/assets/icons/x-close.svg" alt="search-icon" /></span>
        </div>
        <span>Select filters to refine data.</span>
      </div>
      <div class="offers_drawer_body scroll-thin-style">
        <div class="offers_states scroll-thin-style">
          <div class="offers_state_header" (click)="handleVisivility('state')">
            <ng-container *ngIf="isExpanded.state">
              <img src="/assets/icons/chevron-down.svg" alt="search-icon" />
            </ng-container>
            <ng-container *ngIf="!isExpanded.state">
              <img src="/assets/icons/chevron-right.svg" alt="search-icon" />
            </ng-container>
            <span>State</span>
          </div>
          <div class="offers_state_content" *ngIf="isExpanded.state">
            <div *ngFor="let state of filterOptionData.state;let i = index">
              <mat-checkbox color="primary" [checked]="isChecked(state.isChecked)"
                (change)="setFilters($event.checked, 'state', state)">
                {{ state.state }}
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="offers_states scroll-thin-style">
          <div class="offers_state_header" (click)="handleVisivility('department')">
            <ng-container *ngIf="isExpanded.department">
              <img src="/assets/icons/chevron-down.svg" alt="search-icon" />
            </ng-container>
            <ng-container *ngIf="!isExpanded.department">
              <img src="/assets/icons/chevron-right.svg" alt="search-icon" />
            </ng-container>
            <span>Department</span>
          </div>
          <div class="department_search" *ngIf="isExpanded.department">
            <div class="rounded_input_wrap">
              <img src="/assets/icons/search.svg" alt="search-icon" />
              <input class="none_border_outline" [(ngModel)]="departmentSearch"
                (ngModelChange)="searchFilterOption($event,'department')" style="border: none; outline: none"
                placeholder="Quick search" />
            </div>
          </div>
          <div class="offers_state_content offers_departments scroll-thin-style" *ngIf="isExpanded.department">
            <div *ngFor="
                  let department of filterData(filterOptionData.department);
                  let i = index
                " class="department_loop">
              <mat-checkbox color="primary" [checked]="isChecked(department.isChecked)"
                (change)="setFilters($event.checked, 'department', department)">
                {{ department.department }}
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="offers_states scroll-thin-style">
          <div class="offers_state_header" (click)="handleVisivility('role')">
            <ng-container *ngIf="isExpanded.role">
              <img src="/assets/icons/chevron-down.svg" alt="search-icon" />
            </ng-container>
            <ng-container *ngIf="!isExpanded.role">
              <img src="/assets/icons/chevron-right.svg" alt="search-icon" />
            </ng-container>
            <span>Role</span>
          </div>
          <div class="department_search" *ngIf="isExpanded.role">
            <div class="rounded_input_wrap">
              <img src="/assets/icons/search.svg" alt="search-icon" />
              <input class="none_border_outline" [(ngModel)]="roleSearch"
                (ngModelChange)="searchFilterOption($event,'role')" style="border: none; outline: none"
                placeholder="Quick search" />
            </div>
          </div>
          <div class="offers_state_content offers_departments scroll-thin-style" *ngIf="isExpanded.role">
            <div *ngFor="
                  let role of filterData(filterOptionData.role);
                  let i = index
                " class="department_loop">
              <mat-checkbox color="primary" [checked]="isChecked(role.isChecked)"
                (change)="setFilters($event.checked, 'role', role)">
                {{ role.role }}
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="offers_states scroll-thin-style">
          <div class="offers_state_header" (click)="handleVisivility('driver')">
            <ng-container *ngIf="isExpanded.driver">
              <img src="/assets/icons/chevron-down.svg" alt="search-icon" />
            </ng-container>
            <ng-container *ngIf="!isExpanded.driver">
              <img src="/assets/icons/chevron-right.svg" alt="search-icon" />
            </ng-container>
            <span>Driver</span>
          </div>
          <div class="department_search" *ngIf="isExpanded.driver">
            <div class="rounded_input_wrap">
              <img src="/assets/icons/search.svg" alt="search-icon" />
              <input class="none_border_outline" [(ngModel)]="driverSearch"
                (ngModelChange)="searchFilterOption($event,'driver')" style="border: none; outline: none"
                placeholder="Quick search" />
            </div>
          </div>
          <div class="offers_state_content offers_departments scroll-thin-style" *ngIf="isExpanded.driver">
            <div *ngFor="
                  let driver of filterData(filterOptionData.driver);
                  let i = index
                " class="department_loop w-full gap-y-1">

              <div
                class='w-[90%] flex box-border px-2 py-1 rounded justify-between items-center hover:cursor-pointer hover:bg-slate-300'
                (click)="selectDriver(driver.userId)" [ngClass]="{'bg-slate-400':driver.userId===filteredData.driver}">
                <span> {{ driver.driver }}</span>
                <span *ngIf="!(driver.userId===filteredData.driver)"></span>
                <img *ngIf="driver.userId===filteredData.driver" src='/assets/icons/offers/check.svg' />
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="offers_drawer_footer">
        <span (click)="handleClearAllChecked()">Clear Filters</span>
        <div>
          <button class="filter_cancel" (click)="handleVisivility('drawer', false)">
            Cancel
          </button>
          <button class="filter_apply" (click)="handleFilterApply()">
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="header_wrap">
    <div class="search_filter_wrap">
      <div class="align_center">
        <!-- <div class="rounded_input_wrap">
          <img src="/assets/icons/search.svg" alt="search-icon" />
          <input class="none_border_outline" [ngModel]="query.filters!.search" (ngModelChange)="searcherInput($event)"
            style="border: none; outline: none" placeholder="Search" />
        </div> -->
        <div class="offers_filters" type="button" mat-button (click)="handleVisivility('drawer')">
          <img src="/assets/icons/filter-lines.svg" alt="filter-lines-icon" />
          <span>Filters</span>
        </div>
      </div>
      <div class="search_option_wrap">
        <div class="date_range_selection">
          <div class="date_from_selection">
            <div class="hide_item">
              <mat-form-field>
                <input matInput [matDatepicker]="picker" [(ngModel)]="targetDate"
                  (ngModelChange)="handleTargetDateChange($event)" />
                <mat-datepicker #picker [ngClass]="'my-theme-primary'"><mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDatepickerApply>
                      Apply
                    </button>
                  </mat-datepicker-actions></mat-datepicker>
              </mat-form-field>
            </div>
            <span class="date_selection_text whitespace_nowrap">Date:&nbsp;
              <span class="date_selection_date">{{
                getFormattedTime(targetDate)
                }}</span>
            </span>
            <button (click)="picker.open()" class="date_select_button">
              <img src="/assets/icons/calendar.svg" alt="search-icon" />
            </button>
          </div>
        </div>
      </div>
      <div class="custom-slider flex flex-1">
        <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"
          (userChange)="onUserChange($event)"></ngx-slider>
      </div>
    </div>
  </div>
  <mgl-map [style]="'mapbox://styles/mapbox/dark-v9'" [pitch]="[45]" [bearing]="[-17.6]" (load)="onLoad($event)"
    [zoom]="[15]" [center]="center" [cursorStyle]="cursorStyle" [doubleClickZoom]='true'>
    <!-- <mgl-layer *ngIf="geoJsonData.length>0" (mouseEnter)="onMouseEnter($event)" id="route" type="line" [source]="{
        'type': 'geojson',
        'data': {
            'type': 'Feature',
            'properties': {},
            'geometry': {
                'type': 'LineString',
                'coordinates': geoJsonData
            }
        }
      }" [layout]="{
        'line-join': 'round',
        'line-cap': 'round'
      }" [paint]="{
        'line-color': '#03AA46',
        'line-width': 3
      }">
    </mgl-layer> -->

    <ng-container *ngIf="geoJsonData.features.length>0">

      <mgl-geojson-source id="tracking_data" [data]="geoJsonData">
      </mgl-geojson-source>
      <mgl-layer id="unclustered-point" (mouseEnter)="onMouseEnter($event)" (mouseLeave)="onMouseLeave()" type="circle"
        source="tracking_data" [paint]="{
      'circle-color': '#11b4da',
      'circle-radius': 4,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#fff'
    }">
      </mgl-layer>
    </ng-container>

    <ng-container *ngIf="clockingData.features.length>0">

      <mgl-geojson-source id="clocking_data" [data]="clockingData">
      </mgl-geojson-source>
      <mgl-layer id="unclustered-point-clocking" (mouseEnter)="onCommonMouseEnter($event,'clocking')" type="circle"
        source="clocking_data" [paint]="{
      'circle-color': '#EA72E5',
      'circle-radius': 6,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#fff'
    }">
      </mgl-layer>
    </ng-container>

    <mgl-image id="cat" url="./assets/icons/map-location-pin-icon-free-png.png" (loaded)="checkImagesLoaded()">
    </mgl-image>
    <mgl-image id="depot" url="./assets/icons/depot.png" (loaded)="checkImagesLoaded()">
    </mgl-image>
    <ng-container *ngIf="allImagesLoaded">
      <ng-container *ngIf="geoStopsJsonData.features.length>0">

        <mgl-geojson-source id="stops_data" [data]="geoStopsJsonData">
        </mgl-geojson-source>
        <mgl-layer id='icon' type="symbol" source="stops_data" (mouseEnter)="onStopMouseEnter($event)"
          (mouseLeave)="onMouseLeave()" [layout]="{
      'icon-image': 'cat', 
      'icon-size': 0.025,
      'icon-offset': [0, -15],
      'text-field': '{sequence}',
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-offset': [0, -1],
      'text-anchor': 'top'}">
        </mgl-layer>
      </ng-container>

      <ng-container *ngIf="depotData.features.length>0">

        <mgl-geojson-source id="depot_data" [data]="depotData">
        </mgl-geojson-source>
        <mgl-layer id='icon1' type="symbol" source="depot_data" (mouseEnter)="onCommonMouseEnter($event,'depot')"
          [layout]="{
      'icon-image': 'depot', 
      'icon-size': 0.25,
      'icon-offset': [0, -15]}">
        </mgl-layer>
      </ng-container>
    </ng-container>
    <mgl-layer id="3d-buildings" source="composite" sourceLayer="building" [filter]="['==', 'extrude', 'true']"
      type="fill-extrusion" [minzoom]="15" [paint]="{
        'fill-extrusion-color': '#aaa',
        'fill-extrusion-height': [
          'interpolate', ['linear'], ['zoom'],
          15, 0,
          15.05, ['get', 'height']
        ],
        'fill-extrusion-base': [
          'interpolate',
          ['linear'], ['zoom'],
          15, 0,
          15.05, ['get', 'min_height']
        ],
        'fill-extrusion-opacity': .6
      }" [before]="labelLayerId"></mgl-layer>

    <mgl-control mglScale unit="imperial" position="top-right"></mgl-control>
  </mgl-map>
  <app-popup [screenCoordinate]="screenCoordinate" [popupWndSize]="{width:300,height:200}" [isVisible]="true"
    *ngIf="activeStopPopup==='normal'">
    <div class='w-[300px] h-fit rounded-md bg-[#29323c] flex flex-col p-4 box-border gap-2 text-white'>
      <div class='w-full flex justify-between items-center'>
        <span class='text-xs'>Name : </span>
        <span class='text-xs'>{{selected.name}}</span>
      </div>
      <div class='w-full flex justify-between items-center'>
        <span class='text-xs'>Email : </span>
        <span class='text-xs'>{{selected.email}}</span>
      </div>
      <div class='w-full flex justify-between items-center'>
        <span class='text-xs'>Timestamp : </span>
        <span class='text-xs'>{{selected.timestamp}}</span>
      </div>
    </div>
  </app-popup>
  <app-popup [screenCoordinate]="screenCoordinate" [popupWndSize]="{width:300,height:250}" [isVisible]="true"
    *ngIf="activePopup==='clocking'">
    <div class='w-[300px] h-fit rounded-md bg-[#29323c] flex flex-col p-4 box-border gap-2 text-white'>
      <div class='w-full flex justify-between items-center'>
        <span class='text-xs'>Name : </span>
        <span class='text-xs'>{{selected.name}}</span>
      </div>
      <div class='w-full flex justify-between items-center'>
        <span class='text-xs'>{{selected.time_label}}</span>
        <span class='text-xs'>{{selected.time}}</span>
      </div>
      <div class='w-full flex justify-between items-center'>
        <span class='text-xs'>{{selected.check_label}}</span>
        <span class='text-xs'>
          <img
            [src]="selected.check?'/assets/icons/timesheet/clock-valid.svg':'/assets/icons/timesheet/clock_invalid.svg'"
            width='20px' height='auto' />
        </span>
      </div>
      <div class='w-full flex justify-between items-center'>
        <span class='text-xs'>Coordinates::&nbsp;{{selected.clocking_lat}},&nbsp;{{selected.clocking_lng}}</span>
        <span class='text-xs hover:cursor-pointer'
          (click)="copyToClipboard(selected.clocking_lat+','+selected.clocking_lng)"><mat-icon
          *ngIf="!copyStatus" style="font-size:16px;width: 16px;height: 16px;">content_copy</mat-icon>
          <img *ngIf="copyStatus" style='width: 16px;height: 16px;' src="/assets/icons/timesheet/green-checkmark-icon.svg" alt="search-icon" />
        </span>
      </div>
    </div>
  </app-popup>
  <app-popup [screenCoordinate]="screenCoordinate" [popupWndSize]="{width:300,height:150}" [isVisible]="true"
    *ngIf="activePopup==='depot'">
    <div class='w-[300px] h-fit rounded-md bg-[#29323c] flex flex-col p-4 box-border gap-2 text-white'>
      <span class='text-xs'>Depot:&nbsp;{{selected.depot}}</span>
      <span
        class='text-xs flex items-center justify-between'>Coordinates:&nbsp;{{selected.depot_lat}},&nbsp;{{selected.depot_lng}}&nbsp;<span
          class='hover:cursor-pointer' (click)="copyToClipboard(selected.depot_lat+','+selected.depot_lng)"> <mat-icon
            *ngIf="!copyStatus" style="font-size:16px;width: 16px;height: 16px;">content_copy</mat-icon>
            <img *ngIf="copyStatus" style='width: 16px;height: 16px;' src="/assets/icons/timesheet/green-checkmark-icon.svg" alt="search-icon" />
          </span>
      </span>
    </div>
  </app-popup>
  <app-popup [screenCoordinate]="screenCoordinate" [popupWndSize]="{width:300,height:400}" [isVisible]="true"
    *ngIf="activeStopPopup==='stop'">
    <div class='w-[300px] h-fit rounded-md bg-[#29323c] flex flex-col p-4 box-border gap-2 text-white'>
      <div class='w-full flex justify-between items-start'>
        <span class='text-xs whitespace-nowrap'>Address :&nbsp;</span>
        <span class='text-xs'>{{selected.address}}</span>
      </div>
      <div class='w-full flex justify-between items-start'>
        <span class='text-xs whitespace-nowrap'>Business Name : </span>
        <span class='text-xs'>{{selected.business_name}}</span>
      </div>
      <div class='w-full flex justify-between items-start'>
        <span class='text-xs whitespace-nowrap'>Stop Type : </span>
        <span class='text-xs'>{{selected.stop_type}}</span>
      </div>
      <div class='w-full flex justify-between items-start'>
        <span class='text-xs whitespace-nowrap'>Stop Result : </span>
        <span class='text-xs'>{{selected.stop_result}}</span>
      </div>
      <div class='w-full flex justify-between items-start'>
        <span class='text-xs whitespace-nowrap'>Stop Outcome : </span>
        <span class='text-xs'>{{selected.stop_outcome}}</span>
      </div>
      <div class='w-full flex justify-between items-start'>
        <span class='text-xs whitespace-nowrap'>Arrival Time : </span>
        <span class='text-xs'>{{selected.arrival_time}}</span>
      </div>
    </div>
  </app-popup>
</div>