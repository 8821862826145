import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { IndexQuery } from 'src/app/model/IndexQuery';
import {
  DepartmentType,
  InterestedflexiDriver,
  JobShiftFlexiOffer,
  OfferFormat,
  SortObj,
  Timzone,
} from 'src/app/model/custm/JobShiftFlexiOffer';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { FileApi } from 'src/app/services/custm/file.service';
import { JobShiftOfferAPI } from 'src/app/services/custm/job-shift-offer.service';
import {COLUMN_WIDTH_DEFS, EXPORT_COLUMNS, EXPORT_MODEL} from '../../../model/custm/JobShiftFlexiOffer';
import {exportAsExcelFlexi} from '../../../export-excel/Export2Excel';
import { apiUrls } from 'src/app/settings/settings';
import { FlexiJobOffer } from 'src/app/services/custm/flexijoboffers';

@Component({
  selector: 'app-flexi-job-offer',
  templateUrl: './flexi-job-offer.component.html',
  styleUrls: ['./flexi-job-offer.component.scss']
})
export class FlexiJobOfferComponent implements OnInit, OnDestroy {
  @ViewChild('dropdown', { static: false }) dropdownRef?: ElementRef;
  @ViewChild('overlay', { static: false }) overlayRef?: ElementRef;
  @ViewChild('editableP') editableP!: ElementRef;
  @ViewChild('fInput') fInput!: ElementRef;
  staticData: string[] = []; // Static data for the dropdown
  dropdownIndex: number | null = null;

  private counter: number = 0;
  private intervalId: any;

  public offerCreationForm!: FormGroup;
  public modalStatus:
    | 'ASSIGN_CONFIRM'
    | 'REASSIGN_CONFIRM'
    | 'ASSIGN_PAST_CONFIRM'
    | 'DELETE_CONFIRM'
    | 'ASSIGN_FAIL_NOTIF'
    | 'DELETED_NOTIF'
    | 'OPEN_CREATION'
    | 'NONE' = 'NONE';

  public offerDate: Date = new Date();
  public minDate: Date = new Date();

  public assignmentStatus: 'ASSIGNED' | 'UNASSIGNED' = 'ASSIGNED';

  public selected: number = -1;
  public selectedOffer: JobShiftFlexiOffer = {} as JobShiftFlexiOffer;
  public imagePreviewUrl: string = '';
  public attachment: {
    name: string;
    progress: number;
    error: boolean;
    uploadFilePath?: string;
  } = {
    name: '',
    progress: 0,
    error: false,
    uploadFilePath: undefined,
  };

  public hyperLinkPopup: boolean = false;
  public hyperLink: { url: string; label: string } = { url: '', label: '' };
  public job_comments_HTML: any = '';
  public job_comments_TEXT: string = '';
  public _job_comments_HTML: any = '';
  public selection!: Selection;

  //public assigneeDetail!: OfferFormat & { assignee: InterestedflexiDriver };
  public assigneeDetail: any;
  public isKeepOriginal = false;
  public isUpdateStart = false;
  public container: HTMLElement | null = null;

  public multiSelectValid: boolean = true;

  public drivers!: any[];
  public driverSelectForm!: FormGroup;
  public seletedDriver: any;

  public sortObj: SortObj = {
    date: true,
    start_time: true,
    state: true,
    department: true,
    vehicles: true,
    assignedTo: true,
    assignTo: true,
  };

  public timezones: Timzone[] = [
    { state: 'Head Office', timezone: 'Australia/Melbourne' },
    { state: 'NSW', timezone: 'Australia/NSW' },
    { state: 'QLD', timezone: 'Australia/Queensland' },
    { state: 'SA', timezone: 'Australia/Adelaide' },
    { state: 'TAS', timezone: 'Australia/Tasmania' },
    { state: 'VIC', timezone: 'Australia/Victoria' },
    { state: 'WA', timezone: 'Australia/West' },
  ];
  public selTimezone: string = 'Australia/Melbourne';

  public sortOrder: (keyof SortObj)[] = [
    // 'assignedTo',
    // 'vehicles',
    // 'department',
    // 'state',
    // 'start_time',
    // 'date',
  ];

  public sortStringObj = (assignmentStatus: string) => {
    return {
      assignTo: 'LENGTH(interested_drivers)',
      assignedTo:
        '(SELECT CONCAT(COALESCE(name,""), " ", COALESCE(lname,"")) FROM users WHERE users.id = job_shift_offers.assignee_id LIMIT 1)',
      vehicles:
        assignmentStatus === 'ASSIGNED'
          ? 'allocated_vehicle'
          : 'SUBSTRING_INDEX(vehicles, ",", 1)',
      department: 'department',
      state: 'state',
      start_time: 'start_time',
      date: 'date',
    };
  };

  public failedNotifMsg: string = 'This driver already has a job at this time.';

  public originData: DepartmentType[] = [];

  public isRefreshWholeData = false;

  private autoRefresher: any;

  public loading: boolean = false;
  public data: JobShiftFlexiOffer[] = [];
  public dataAssigned: JobShiftFlexiOffer[] = [];
  public dataUnassigned: JobShiftFlexiOffer[] = [];
  public searchModel: string = '';
  public isExpanded = {
    state: false,
    department: false,
    vehicle: false,
    drawer: false,
  };

  public selectedTime: string;

  public toExport: boolean = false;

  public range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  public searchCooldown: any;

  public query: IndexQuery = {
    page: 1,
    perPage: 20,
    sortBy: 'created_at',
    sortDir: 'desc',
    filters: {
      search: '',
      tz: String(new Date().getTimezoneOffset()),
    },
  };

  public sorting:string = 'asc';
  public sortColumn: string = 'despatch_date_local'

  public hasMore: boolean = true;
  // Add a flag to indicate if data is currently being loaded
  private loadingData: boolean = false;
  public total: number = 0;
  public filtered: number = 0;
  public departmentSearch: string = '';
  public vehicleSearch: string = '';

  public state_department_data: {
    state: DepartmentType[];
    department: DepartmentType[];
    vehicle: DepartmentType[];
  } = {
    state: [],
    department: [],
    vehicle: [],
  };

  filteredData: {
    state: { label: string; id: string }[];
    department: { label: string; id: string }[];
    vehicle: { label: string; id: string }[];
  } = {
    state: [],
    department: [],
    vehicle: [],
  };

  public previousDates: {
    fromDate: Date;
    toDate: Date;
  } = {
    fromDate: new Date(),
    toDate: new Date(),
  };

  public filterVehicles: any = [
      { id: 1, vehicle: '1-tonne Van', isChecked: false },
      { id: 2, vehicle: '2-tonne Van', isChecked: false },
      { id: 3, vehicle: '3-tonne Courier Truck', isChecked: false },
      { id: 4, vehicle: '10 Pallet Truck', isChecked: false },
      { id: 5, vehicle: '12 Pallet Truck', isChecked: false },
      { id: 6, vehicle: '14 Pallet Truck', isChecked: false }
    ]

  public filterStatus: any = [
      { id: 1, status: 'Manifested', isChecked: false },
      { id: 2, status: 'Trip Started', isChecked: false },
      { id: 3, status: 'Picked Up', isChecked: false },
      { id: 4, status: 'In Transit', isChecked: false },
      { id: 5, status: 'Delivered', isChecked: false }
    ]

  public fromDate: Date = new Date();
  public toDate: Date = new Date();

  public states$: Observable<string[]>;
  public departments$!: Observable<string[]>;
  public vehicles$!: Observable<string[]>;
  selectedVehicles: string[] = [];
  public filteredDriveOnSearch: string = '';

  public formattedFromDate:any;
  public formattedToDate:any;

  public allEligibleDrivers: any = [];
  public eligibleDrivers: any = [];
  public selectedDriver: any;
  public selectedDriverIndex: any;

  public total_unassigned: number = 0;
  public total_manifested: any = [];
  public total_tripstarted: any = [];
  public total_pickedup: any = [];
  public total_intransit: any = [];
  public total_delivered: any = [];


  constructor(
    private formBuilder: FormBuilder,
    private jobShiftOfferApi: JobShiftOfferAPI,
    private fileApi: FileApi,
    private cdref: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private flexiJobService: FlexiJobOffer
  ) {
    this.initializeDates();
    this.states$ = jobShiftOfferApi
      .getStates()
      .pipe(map((res) => (res?.data || []) as string[]));

    this.minDate.setHours(0);
    this.minDate.setMinutes(0);
    this.minDate.setSeconds(0);

    const currentTime = new Date();
    this.selectedTime = this.getFormattedClocktime(currentTime);
  }

  ngOnInit(): void {
    this.initializeForm();
    this.offerCreationForm.get('state')?.valueChanges.subscribe((value) => {
      this.offerCreationForm.get('department')?.setValue('');
      // this.offerCreationForm.get('vehicle')?.setValue('');
      this.selectedVehicles = [];
      this.departments$ = this.jobShiftOfferApi
        .getDepartments(value)
        .pipe(map((res) => (res?.data || []) as string[]));
      });

      this.offerCreationForm
          .get('department')
          ?.valueChanges.subscribe((value) => {
        // this.offerCreationForm.get('vehicle')?.setValue('');

        this.selectedVehicles = [];

        // this.jobShiftOfferApi.getVehicles(this.offerCreationForm.get('state')?.value, value).subscribe((res) => {
        //   this.vehicles = (res?.data || []) as string[];
        // });
        this.vehicles$ = this.jobShiftOfferApi
            .getVehicles(this.offerCreationForm.get('state')?.value, value)
            .pipe(map((res) => (res?.data || []) as string[]));
      });
      // Get states and departments data.
      this.jobShiftOfferApi.getUsedDepartments().subscribe((res: any) => {
        const { data } = res;
        // this.originData = data?.statesWithVehicles ?? [];
        const state = _.groupBy(data?.statesWithDepartments, 'state');
        // const vehicles = _.groupBy(data?.statesWithVehicles, 'vehicle');

        const flattenedData = _.flatMap(data?.statesWithVehicles, (entry) => {
          return entry.vehicles.map((vehicle: string) => ({
            state: entry.state,
            department: entry.department,
            vehicle,
          }));
        });

        const filteredData = _.uniqWith(flattenedData, _.isEqual);

        this.originData = filteredData;
        const vehicles = _.groupBy(filteredData, 'vehicle');

        const department = data?.statesWithDepartments as DepartmentType[];
        // Stores states and departments to state_department_data variable.
        this.state_department_data.state = Object.keys(state).map(
            (item) =>
                ({
                  state: item,
                  isChecked: false,
                  isVisible: true,
                } as DepartmentType)
        );
        this.state_department_data.vehicle = Object.keys(vehicles).map(
            (item) =>
                ({
                  vehicle: item,
                  isChecked: false,
                  isVisible: true,
                } as DepartmentType)
        );
        this.state_department_data.department = department;
        // Update screen after store data to state_department_data.
        this.cdref.detectChanges();
    });
    this.fetchData();
    this.startCounterAssigned();

  }

  ngOnDestroy(): void {
    this.stopCounter();
  }


  initializeForm() {
    // this.driverSelectForm = this.formBuilder.group({
    //   driver: ['', [Validators.required]],
    // });
    this.offerCreationForm = this.formBuilder.group(
      {
        date: [this.getFormattedTime(this.offerDate), [Validators.required]],
        state: ['', [Validators.required]],
        department: ['', [Validators.required]],
        charging_comments: '',
      },
    );

    this.jobShiftOfferApi.getDrivers().subscribe((res) => {
      this.drivers = (res.data ?? [])
          .map((item: any) => ({
            ...item,
            FullName: `${item.FirstName} ${item.LastName}`,
          }))
          .filter(
              (item: any) => Boolean(item.FirstName) || Boolean(item.LastName)
          );
    });
  }




toggleDropdown(index: number) {
  this.dropdownIndex = this.dropdownIndex === index ? null : index;
}

// Define action for fromDate change
public handleFromDateChange(e: string) {
  // const fromDate = new Date(e);
  // const toDate = new Date(this.toDate);

  // // If fromDate is greater than toDate, adjust toDate to match fromDate
  // if (fromDate.getTime() > toDate.getTime()) {
  //   this.toDate = new Date(fromDate);
  //   this.toDate.setHours(23);
  //   this.toDate.setMinutes(59);
  // }

  // // Set fromDate and adjust time
  // this.fromDate = fromDate;
  // this.fromDate.setHours(0);
  // this.fromDate.setMinutes(0);

  // Fetch data with the updated dates
  this.fetchData();
}

// Define action for toDate change
public handleToDateChange(e: string) {
  // const fromDate = new Date(this.fromDate);
  // const toDate = new Date(e);

  // this.formattedFromDate = moment(this.fromDate).format('YYYY-MM-DD');
  // this.formattedToDate = moment(this.toDate).format('YYYY-MM-DD');

  // // If toDate is earlier than fromDate, adjust fromDate to match toDate
  // if (toDate.getTime() < fromDate.getTime()) {
  //   this.fromDate = new Date(toDate);
  //   this.fromDate.setHours(0);
  //   this.fromDate.setMinutes(0);
  // }

  // // Set toDate and adjust time
  // this.toDate = toDate;
  // this.toDate.setHours(23);
  // this.toDate.setMinutes(59);

  // Fetch data with the updated dates
  this.fetchData();
  
}

startCounterAssigned() {
    this.intervalId = setInterval(() => {
      this.counter++;
      this.getFlexiJobAssigned();
    }, 60000); 
  }

  startCounterUnassigned() {
    this.intervalId = setInterval(() => {
      this.counter++;
      this.getFlexiJobUnassigned();
    }, 10000); 
  }

  stopCounter() {
    clearInterval(this.intervalId);
     this.fetchData();
  }



public handleAssignmentStatus(value: 'ASSIGNED' | 'UNASSIGNED') {

  this.stopCounter();
  this.assignmentStatus = value;
  if (value === 'UNASSIGNED') {
    this.startCounterUnassigned();
    
  } else if (value === 'ASSIGNED'){
    this.startCounterAssigned();
  } 
  
  
}

public getFormattedClocktime(time: Date) {
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const isAfternoon = hours >= 12;
  return `${
      isAfternoon ? (hours === 12 ? 12 : hours - 12) : hours
  }:${minutes} ${isAfternoon ? 'PM' : 'AM'}`;
}

public getFlexiJobAssigned() {
   // Convert dates to the format 'YYYY-MM-DD'
  this.formattedFromDate = moment(this.fromDate).format('YYYY-MM-DD');
  this.formattedToDate = moment(this.toDate).format('YYYY-MM-DD');

  const orderArr = this.sortOrder.map(
      (item) =>
          `${this.sortStringObj(this.assignmentStatus)[item]} ${
              this.sortObj[item] ? 'ASC' : 'DESC'
          }`
  );
  // Default to getFlexiJobAssigned
   let serviceCall = this.jobShiftOfferApi.getFlexiJobAssigned(
      this.query,
      this.formattedFromDate,
      this.formattedToDate,
      this.filteredData.state.map((item) => item.label),
      this.filteredData.department.map((item) => item.label),
      this.filteredData.vehicle.map((item) => item.label),
      orderArr,
      this.isRefreshWholeData
  );
   // Call the appropriate service
    serviceCall.subscribe((r) => {
      let res = CodedResponseModel.decode(r);
      // Safeguard against undefined or null data
      this.dataAssigned = res;
      const data = res.data || [];

      // Initialize table data
      this.dataAssigned = [
        ...this.dataAssigned,
        ...data.map((s: any) => JobShiftFlexiOffer.create(s)),
      ];
      this.filtered = res.filtered || 0;
      this.total = res.total || 0;
      
      this.loading = false;

      this.total_tripstarted = this.dataAssigned.filter((item) => item.status_name === "Trip Started");
      this.total_intransit = this.dataAssigned.filter((item) => item.status_name === "In Transit");
      this.total_pickedup = this.dataAssigned.filter((item) => item.status_name === "Picked Up");
      this.total_manifested = this.dataAssigned.filter((item) => item.status_name === "Manifested");
      this.total_delivered = this.dataAssigned.filter((item) => item.status_name === "Delivered");


    }, (error) => {
      console.error('Error fetching flexi job data:', error);
      this.loading = false;
    });

}

public getFlexiJobUnassigned() {
   // Convert dates to the format 'YYYY-MM-DD'
  this.formattedFromDate = moment(this.fromDate).format('YYYY-MM-DD');
  this.formattedToDate = moment(this.toDate).format('YYYY-MM-DD');

  const orderArr = this.sortOrder.map(
      (item) =>
          `${this.sortStringObj(this.assignmentStatus)[item]} ${
              this.sortObj[item] ? 'ASC' : 'DESC'
          }`
  );

  // Override if the assignment status is UNASSIGNED
    let serviceCall1 = this.jobShiftOfferApi
        .getFlexiJobunAssigned(
            this.query,
            this.formattedFromDate,
            this.formattedToDate,
            this.filteredData.state.map((item) => item.label),
            this.filteredData.department.map((item) => item.label),
            this.filteredData.vehicle.map((item) => item.label),
            orderArr,
            this.isRefreshWholeData
        );
        // Call the appropriate service
        serviceCall1.subscribe((r) => {
          let res = CodedResponseModel.decode(r);
          // Safeguard against undefined or null data
          this.dataUnassigned = res;
          const data = res.data || [];

          // Initialize table data
          this.dataUnassigned = [
            ...this.dataUnassigned,
            ...data.map((s: any) => JobShiftFlexiOffer.create(s)),
          ];
          this.filtered = res.filtered || 0;
          this.total = res.total || 0;
          this.total_unassigned = res.length;

          this.loading = false;
        }, (error) => {
          console.error('Error fetching flexi job data:', error);
          this.loading = false;
        });

}

public fetchData(isRefresh = false, /*infinite = false, */isExport = false) {
  this.getFlexiJobAssigned();
  this.getFlexiJobUnassigned()

}


public machshipSync(isRefresh = false, infinite = false) {
  if (this.assignmentStatus !== 'UNASSIGNED') return;

  // Make it loading status
  if (!isRefresh) this.loading = true;

  // Default to machshipSync
  let serviceCall = this.jobShiftOfferApi.machshipSync();

  // Call the appropriate service
  serviceCall.subscribe((r: any ) => {
    if(r['status'] === 'success') {
       this.handleAssignmentStatus('UNASSIGNED')
       NotificatorPartial.push({
          type: 'success',
          message: r['message'],
          timeout: 5000,
        });
       // Stop data loading
       this.loading = false;
    }
    //let res = CodedResponseModel.decode(r);
    // // Safeguard against undefined or null data
    // this.data = res;
    // const data = res.data || [];


    
  }, (error) => {
    console.error('Error fetching flexi job data:', error);
    this.loading = false;
  });
}

public downloadData() {
  if (!this.data.length)
    return NotificatorPartial.push({
      type: 'error',
      message: 'No data exist for current selection',
      timeout: 3000,
    });
  this.loading = true;
  this.fetchData(false, true);
}


public openMap(pickupAddress: string, dropoffAddress: string) {
  const encodedPickupAddress = encodeURIComponent(pickupAddress);
  const encodedDropoffAddress = encodeURIComponent(dropoffAddress);
  const mapUrl = `https://www.google.com/maps/dir/?api=1&origin=${encodedPickupAddress}&destination=${encodedDropoffAddress}`;
  window.open(mapUrl, '_blank');
}

// Returns departments data by filtering based on selected states and search keyword.
public filterDepartments(arr: DepartmentType[]) {
  const checkedstates = this.state_department_data.state.filter(
      (item) => item.isChecked
  );

  if (checkedstates.length)
    return arr.filter(
        (item) =>
            (item?.isVisible === undefined || item?.isVisible === true) &&
            checkedstates.map((subItem) => subItem.state).includes(item.state)
    );
  else
    return arr.filter(
        (item) => item?.isVisible === undefined || item?.isVisible === true
    );
}


// public filterVehicles(arr: DepartmentType[]) {
//   const checkedstates = this.state_department_data.state
//       .filter((item) => item.isChecked)
//       .map((item) => item.state);

//   const checkedDepartmentsLength =
//       this.state_department_data.department.filter(
//           (item) => item.isChecked
//       ).length;

//   const checkedDepartments = this.state_department_data.department
//       .filter((item) =>
//           checkedstates.length > 0
//               ? checkedDepartmentsLength > 0
//                   ? item.isChecked && checkedstates.includes(item.state)
//                   : checkedstates.includes(item.state)
//               : item.isChecked
//       )
//       .map((item) => `${item.state}-${item.department}`);

//   const relatedVehicles = this.originData
//       .filter((item) =>
//           checkedDepartments.includes(`${item.state}-${item.department}`)
//       )
//       .map((item) => item.vehicle);

//   if (relatedVehicles.length)
//     return arr.filter(
//         (item) =>
//             (item?.isVisible === undefined || item?.isVisible === true) &&
//             relatedVehicles.includes(item.vehicle)
//     );
//   else
//     return arr.filter(
//         (item) => item?.isVisible === undefined || item?.isVisible === true
//     );
// }































private initializeDates() {
  // Set fromDate to one month back from today
  // this.fromDate = new Date();
  // this.fromDate.setMonth(this.fromDate.getMonth() - 1;
  // this.fromDate.setHours(0);
  // this.fromDate.setMinutes(0);
  this.fromDate.setDate(this.toDate.getDate() - 1);

  // Set toDate to today's date
  // this.toDate = new Date();
  // this.toDate.setHours(23);
  // this.toDate.setMinutes(59);
  this.toDate.setDate(this.toDate.getDate() + 7);
}



      // Set isChecked value based on user control in filter modal for state and department checkboxes.
  public setFilters(
    checked: boolean,
    key: 'state' | 'department' | 'vehicle',
    value: DepartmentType
  ) {
    const selectedIndex =
      key === 'state' || key === 'vehicle'
        ? this.state_department_data[key].findIndex(
            (item) => item[key] === value[key]
          )
        : this.state_department_data.department.findIndex(
            (item) =>
              item.state === value.state && item.department === value.department
          );
    this.state_department_data[key][selectedIndex].isChecked = checked;
  }

  public handleSelectedState(event: MatAutocompleteSelectedEvent) {
    const state = event.option.value;
    const { start_time, date } = this.offerCreationForm.value;
    this.selTimezone =
        this.timezones.find((item) => item.state === state)?.timezone ??
        'Australia/Melbourne';
    const stateCurTime = moment().tz(this.selTimezone);
    const selStateTimestamp = moment
        .tz(`${date} ${start_time}`, 'DD/MM/YYYY h:mm A', this.selTimezone)
        .unix();
    if (selStateTimestamp < stateCurTime.unix()) {
      const new_start_time = stateCurTime.format('h:mm A');
      this.offerCreationForm
          .get('start_time')
          ?.setValue(stateCurTime.format('h:mm A'));
      this.offerCreationForm
          .get('date')
          ?.setValue(stateCurTime.format('DD/MM/YYYY'));
      this.selectedTime = new_start_time;
      // Manually trigger change detection
      this.cdref.detectChanges();
    }
  }




  public handleStartTimeChange(e: any) {
   this.offerCreationForm.get('start_time')?.setValue(e);
  }

  // Remove inputs in search driver field
  public clearSearch() {
    this.query.filters!.search = '';
    this.reset();
    this.fetchData();
  }

  // Remove all filters including search keyword in Search Driver field.
  public handleClearAllFilters() {
    this.filteredData = { state: [], department: [], vehicle: [] };
    this.query.filters!.search = '';
    this.state_department_data.state = this.state_department_data.state.map(
        (item) => ({
          ...item,
          isChecked: false,
        })
    );
    this.state_department_data.department =
        this.state_department_data.department.map((item) => ({
          ...item,
          isChecked: false,
        }));
    this.state_department_data.vehicle = this.state_department_data.vehicle.map(
        (item) => ({
          ...item,
          isChecked: false,
        })
    );
    this.initializeDates()
    this.reset();
    this.fetchData();
  }

  // Clear all checks from filter modal
  public handleClearAllChecked() {
    this.state_department_data.state = this.state_department_data.state.map(
        (item) => ({
          ...item,
          isChecked: false,
        })
    );
    this.state_department_data.department =
        this.state_department_data.department.map((item) => ({
          ...item,
          isChecked: false,
        }));
    this.state_department_data.vehicle = this.state_department_data.vehicle.map(
        (item) => ({
          ...item,
          isChecked: false,
        })
    );
  }

  // Define action for fromdate change
  public handleOfferDateChange(e: string) {
    const offerDate = moment(e).format('DD/MM/YYYY');
    this.offerCreationForm.get('date')?.setValue(offerDate);
  }

 

 

  // Returns formated time with DD/MM/YY format
  getFormattedTime(value: Date) {
    return moment(value).format('DD/MM/YY');
  }

  public handleVisivility(
    key: 'state' | 'department' | 'vehicle' | 'drawer',
    value: boolean | null = null
  ) {
    // If the user close filter window without apply, it will ignore what the user newly checked.
    if (key === 'drawer' && value === false) {
      this.state_department_data.state.forEach((item, itemIndex) => {
        this.state_department_data.state[itemIndex].isChecked =
          this.filteredData.state.map((item) => item.id).includes(item.state);
      });
      this.state_department_data.vehicle.forEach((item, itemIndex) => {
        this.state_department_data.vehicle[itemIndex].isChecked =
          this.filteredData.vehicle
            .map((item) => item.id)
            .includes(item.vehicle);
      });
      this.state_department_data.department.forEach((item, itemIndex) => {
        this.state_department_data.department[itemIndex].isChecked =
          this.filteredData.department
            .map((item) => item.id)
            .includes(`${item.state}-${item.department}`);
      });
    }

    // It does expand action for state, department in filter and filter modal.
    if (value !== null) {
      this.isExpanded[key] = value;
      return;
    }
    this.isExpanded[key] = !this.isExpanded[key];
  }

  // This function define actions when user click apply button in filter modal.
  public handleFilterApply() {

    this.isExpanded.drawer = false;
    this.filteredData = { state: [], department: [], vehicle: [] };
    const filteredstates: string[] = [];

    // Stored filtered states data.
    this.state_department_data.state.forEach((item) => {
      if (item.isChecked) {
        filteredstates.push(item.state);
        this.filteredData.state.push({
          label: item.state,
          id: item.state,
        });
      }
    });

    // If the filtered states data is not empty, it will also fiter departments data which are related to filtered states.
    if (filteredstates.length)
      this.state_department_data.department =
        this.state_department_data.department.map((item) => ({
          ...item,
          isChecked: filteredstates.includes(item.state)
            ? item.isChecked
            : false,
        }));

    // Store checked departments data.

    const filteredDepartmentVehicles: string[] = [];

    this.state_department_data.department.forEach((item) => {
      if (item.isChecked) {
        filteredDepartmentVehicles.push(item.vehicle);
        this.filteredData.department.push({
          label: `${item.department}`,
          id: `${item.state}-${item.department}`,
        });
      }
    });

    const checkedDepartmentsLength =
      this.state_department_data.department.filter(
        (item) => item.isChecked
      ).length;

    const checkedDepartments = this.state_department_data.department
      .filter((item) =>
        filteredstates.length > 0
          ? checkedDepartmentsLength > 0
            ? item.isChecked && filteredstates.includes(item.state)
            : filteredstates.includes(item.state)
          : item.isChecked
      )
      .map((item) => `${item.state}-${item.department}`);

    const relatedVehicles = this.originData
      .filter((item) =>
        checkedDepartments.includes(`${item.state}-${item.department}`)
      )
      .map((item) => item.vehicle);

    if (relatedVehicles.length)
      this.state_department_data.vehicle =
        this.state_department_data.vehicle.map((item) => ({
          ...item,
          isChecked: relatedVehicles.includes(item.vehicle)
            ? item.isChecked
            : false,
        }));

    this.state_department_data.vehicle.forEach((item) => {
      if (item.isChecked) {
        this.filteredData.vehicle.push({
          label: item.vehicle,
          id: item.vehicle,
        });
      }
    });

    this.reset();
    this.fetchData();
  }

  // Check data and returns its boolean value
  public isChecked(value: boolean | undefined | null) {
    return Boolean(value);
  }

  // Difine actions for user inputs in Quick serach in filter modal.
  public searchFilterOption(
    value: string,
    keyValue: 'state' | 'department' | 'vehicle'
  ) {
    this.state_department_data[keyValue].forEach((item, itemIndex) => {
      if (`${item[keyValue]}`.toLowerCase().includes(value.toLowerCase())) {
        this.state_department_data[keyValue][itemIndex].isVisible = true;
      } else {
        this.state_department_data[keyValue][itemIndex].isVisible = false;
      }
    });
  }




  // Define action for user inputs in Search field.
  public searcherInput(value: string) {
    this.query.filters!.search = value;

    // It fetech data after 0.5 seconds when user input stops
    if (this.searchCooldown) clearTimeout(this.searchCooldown);
    this.searchCooldown = setTimeout(() => {
      this.reset();
      this.fetchData();
    }, 500);
  }


  public handleSort(value: keyof SortObj) {
    this.sortObj[value] = !this.sortObj[value];
    const isForAssign = value === 'assignTo' || value === 'assignedTo';

    const index = this.sortOrder.findIndex((item) =>
      isForAssign
        ? item === 'assignTo' || item === 'assignedTo'
        : item === value
    );
    this.sortOrder = [
      ...this.sortOrder.slice(0, index),
      ...this.sortOrder.slice(index + 1),
    ];
    this.sortOrder.push(value);
    this.reset();
    this.fetchData();
  }

  public onScroll(event: Event): void {
    this.container = event.target as HTMLElement;
    const tolerance = 1; // Set a tolerance value based on your requirements
    const isScrolledToBottom =
      this.container.scrollHeight - this.container.scrollTop <=
      this.container.clientHeight + tolerance;
    // if (isScrolledToBottom && this.hasMore && !this.loadingData) {
    //   this.loadingData = true; // Set the loading flag to prevent multiple calls
    //   this.loadData(); // Load more data
    // }
  }

  // public loadData() {
  //   this.isRefreshWholeData = false;
  //   this.query.page = (this.query?.page ?? 0) + 1;
  //   this.fetchData(false, true);
  // }

  public reset() {
    // this.query.page = 1;
    this.isRefreshWholeData = true;
    if (this.container) {
      this.container.scrollTop = 0;
    }
    // this.hasMore = true;
  }

  public replaceEmplyee(text: string, replaceText: string) {
    return text.replace(/employee/gi, replaceText);
  }

  public onFileSelected(e: any) {
    const file: File = e.target.files[0];
    this.attachment.name = file.name;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.imagePreviewUrl = e.target.result;
      this.fileApi.upload(this.imagePreviewUrl, 'photo').subscribe(
        (r) => {
          switch (r.type) {
            case HttpEventType.UploadProgress:
              this.attachment.progress = Math.floor(
                (r.loaded / (r.total as number)) * 99
              );
              break;
            case HttpEventType.Response:
              let res = CodedResponseModel.decode(r.body);
              this.attachment.uploadFilePath = res.path;
              this.attachment.progress = 100;
              break;
          }
        },
        (err) => {
          this.attachment.error = true;
        }
      );
    };

    reader.readAsDataURL(file);
  }
  public handleClearAttachment() {
    if (this?.fInput?.nativeElement) this.fInput.nativeElement.value = null;
    this.imagePreviewUrl = '';
    this.attachment = {
      name: '',
      progress: 0,
      error: false,
    };
  }

  public openHyperLinkPopup() {
    this.hyperLinkPopup = true;
  }

  public insertHyperLink() {
    if (!this.isValidUrl(this.hyperLink.url) || !this.hyperLink.label)
      return NotificatorPartial.push({
        type: 'warning',
        message: 'Please enter a valid url and Text',
        timeout: 3000,
      });

    const updated_content = `${this._job_comments_HTML}<a href="${this.hyperLink.url}" rel="noopener noreferrer" target="_blank" contenteditable="false">${this.hyperLink.label}</a>`;

    this.job_comments_HTML =
      this.sanitizer.bypassSecurityTrustHtml(updated_content);

    this.editableP.nativeElement.innerHTML = this.job_comments_HTML;
    this._job_comments_HTML = updated_content;

    const element: HTMLElement = document.createElement('div');
    element.innerHTML = updated_content;
    this.job_comments_TEXT = element.innerText;
    this.cancelPopup();
  }

  public cancelPopup() {
    this.hyperLink = { url: '', label: '' };
    this.hyperLinkPopup = false;
  }
  public isValidUrl(url: string) {
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return pattern.test(url);
  }
  public handlePInput(event: any) {
    const element = event.target as HTMLParagraphElement;
    this._job_comments_HTML = element.innerHTML;
    this.job_comments_TEXT = element.innerText;
  }
  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const text = clipboardData.getData('text');

    // Insert plain text at the cursor position
    document.execCommand('insertText', false, text);
  }
  public handleSelectedEvent(value: string) {
    this.multiSelectValid = true;
    if (this.selectedVehicles.includes(value)) {
      this.selectedVehicles = [
        ...this.selectedVehicles.filter((fruit) => fruit !== value),
      ];
    } else {
      this.selectedVehicles.push(value);
    }
  }
  public onRowSelect(driver: any, flexiJobHeaderId: number) {
    this.loading = true;
    this.dropdownIndex = 0.1;
    this.jobShiftOfferApi.assignFlexiJobActive(flexiJobHeaderId, driver.driver_id, driver.vehicle_type_id).subscribe({
      next: (response: any) => {
        NotificatorPartial.push({
          type: 'success',
          message: "The job has been successfully assigned to the driver.",
          timeout: 3000,
        });
        this.fetchData();
        this.loading = false;
      },
      error: (error) => {
        console.error('API call failed:', error);
        this.loading = false;
      }
    });
  }

  public assignToDriver(driver: any, flexiJobHeaderId: number) {

    this.loading = true;
    this.jobShiftOfferApi.assignFlexiJobActive(flexiJobHeaderId, driver.driver_id, driver.vehicle_type_id).subscribe({
      next: (response: any) => {
        NotificatorPartial.push({
          type: 'success',
          // message: response['message'],
          message: "The job has been successfully assigned to the driver.",
          timeout: 3000,
        });
        this.fetchData();
        this.loading = false;
        this.closeModal();
      },
      error: (error) => {
        console.error('API call failed:', error);
        this.loading = false;
        this.closeModal();
      }
    });
  }

  public filterDrivers(drivers: any[]) {
    return drivers.filter((item) =>
        item.FullName.toLowerCase().includes(
            this.filteredDriveOnSearch.toLowerCase()
        )
    );
  }

  public assignDriver(flexiJobHeaderId: number) {
    this.seletedDriver.driver_id = this.seletedDriver.UserID
    this.seletedDriver.vehicle_type_id = 1
    this.onRowSelect(this.seletedDriver, flexiJobHeaderId);
    this.dropdownIndex = null;
  }

  public handleAssignOffer(offer: any, value: any) {
    this.selected = -1;
    this.assigneeDetail = offer;
    this.modalStatus = value;
    this.eligibleDrivers = [];
    this.allEligibleDrivers = [];
    this.selectedDriver = [];
    this.selectedDriverIndex = [];

    this.jobShiftOfferApi.getEligibleDriver(offer.flexi_job_header_id).subscribe({
      next: (response: any) => {
        this.eligibleDrivers = response;
        this.allEligibleDrivers = response;
      },
      error: (error) => {
        console.error('API call failed:', error);
      }
    });

  }

  public sendPushNotification (manifest_id: any) {

    this.jobShiftOfferApi.sendPushNotification(manifest_id).subscribe({
      next: (response: any) => {
        NotificatorPartial.push({
          type: 'success',
          message: 'Push notification has been successfully sent to the drivers.',
          timeout: 5000,
        });
      },
      error: (error) => {
        console.error('API call failed:', error);
      }
    });

  }


  public downloadImagePOD(consignment_id: any) {
      const link = document.createElement('a');
      link.href = `${apiUrls.apiUrl}download-pod-photo/${consignment_id}`
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }

  public downloadImageSignature(consignment_id: any) {
      const link = document.createElement('a');
      link.href = `${apiUrls.apiUrl}download-pod-signature/${consignment_id}`
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }



  public handleSelectDriver(value: any) {
    this.selectedDriver = value;
  }

  public selectDriver(index: number, value: any){
    this.selectedDriverIndex = index;
    this.selectedDriver = value;
  }

  public closeModal() {
    this.modalStatus = 'NONE';
  }

  public searcherDriverInput(searchText: any) {
    let jsonArray: any[] = this.allEligibleDrivers;
    const lowerCaseSearchText = searchText.toLowerCase();

    this.eligibleDrivers = jsonArray.filter(item => {
        return Object.values(item).some(value => {
            if (typeof value === 'string') {
                return value.toLowerCase().includes(lowerCaseSearchText);
            } else if (typeof value === 'number' || typeof value === 'boolean') {
                return value.toString().toLowerCase().includes(lowerCaseSearchText);
            }
            return false;
        });
    });
  }

  // sortData(key: string, data:n ) {
  //   let order = this.sorting === 'asc' ? 'desc' : 'asc';
  //   this.sortColumn = key;
  //   this.sorting = order;
  //   this.dataAssigned.sort((a, b) => {
  //     if (a[this.sortColumn] < b[this.sortColumn]) {
  //       return order === 'asc' ? -1 : 1;
  //     } else if (a[this.sortColumn] > b[this.sortColumn]) {
  //       return order === 'asc' ? 1 : -1;
  //     } else {
  //       return 0;
  //     }
  //   });
  // }

  public exportToExcel() {
    
        const exportData = [
          { v: 'ManifestID' },
          { v: 'Consignment Number' },
          { v: 'Carrier Consignment ID' },
          { v: 'Despatch Date' },
          { v: 'State' },
          { v: 'Status' },
          { v: 'Client' },
          { v: 'Pickup Address' },
          { v: 'Dropoff Address' },
          { v: 'Driver' },
          { v: 'Vehicle' },
          { v: 'Service Type' },
          { v: 'Cost' },
          { v: 'Sell Price' },
          { v: 'Comment' },
          { v: 'Comment by' }
        ];
        const tempData = this.dataAssigned.map((item) => [
          {
            v: String(item.manifest_id),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.consignment_number),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.carrier_consignment_id),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.despatch_date_local),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.pickup_header_state),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.status_name),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.company_name),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.pickup_full_address),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.dropoff_full_address),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.driver_name),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.vehicle_type_name),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.service_names),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.total_cost_price),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: String(item.total_sell_price),
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: item.comment ? item.comment : "",
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          },
          {
            v: (item.commented_by) ? item.commented_by.lname + ' ' + item.commented_by.name : "",
            t: 's',
            s: { alignment: { vertical: 'top', horizontal: 'left' } },
          }
          // {
          //   v: String(item.attachment.join('\n')),
          //   t: 's',
          //   s: { alignment: { wrapText: true } },
          // },
        ]);
        // Export data to xls file.
        exportAsExcelFlexi([exportData, ...tempData]);

        // Make the export status by false
        this.toExport = false;

        // Stop loading
        this.loading = false;
      }

  public openAddComment(index: any = null, elem: any) {
    const textareas = document.querySelectorAll('textarea.offer_comment');
    const textarea = textareas[index];
    textarea.parentElement?.classList.remove('hidden');
    const txt = textarea as HTMLElement;
    txt.focus();
    elem.target.classList.add('hidden');
  }
  public updateTable (elem: any, id: number = 0) {
    if (id != 0) {
      const data = {'comment':  elem.target.value};
      this.flexiJobService.update(data, id)
      .subscribe(
        {
          next: (response: any) => {
            if (response.success) {
              this.getFlexiJobAssigned();
            }
          },
          error: (error: HttpErrorResponse) => {
            console.log(error);
          }
        }
      );
    }
  }
}

