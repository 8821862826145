<form class='w-full grid grid-cols-12 box-border p-5 bg-[#F5F8FF] gap-8' [formGroup]="clientCreationForm"
    #formRef="ngForm" (ngSubmit)="save()">
    <ng-container *ngIf="!editMode">
        <div class='lg:col-span-3 col-span-12 flex flex-col bg-white h-fit rounded-md box-border px-4 py-5'>
            <span>Clients</span>
            <div class=' box-border py-4 flex flex-col'>
                <span
                    class='w-full flex justify-start items-center box-border mx-1 px-3 py-2 rounded-md text-[#101828] text-base font-semibold leading-6 bg-[#F9FAFB] hover:cursor-pointer'
                    (click)="scrollToDetails()">Details</span>

                <span class='text-[#101828] text-base font-semibold leading-6 px-4 mt-2 hover:cursor-pointer'
                    (click)="scrollToChargingTerms()">Charging Terms</span>
                <div class='flex flex-col items-start px-4'>

                    <div *ngIf="!(vehicles.length>0)" class='flex justify-start items-center gap-x-2'>
                        <input type='checkbox' (change)="handleInvalidCheck()" />
                        <span>DEDI</span>
                    </div>
                    <div *ngIf="!(vehicles.length>0)" class='flex justify-start items-center gap-x-2'>
                        <input type='checkbox' (change)="handleInvalidCheck()" />
                        <span>FLEXI</span>
                    </div>
                    <div *ngIf="!(vehicles.length>0)" class='flex justify-start items-center gap-x-2'>
                        <input type='checkbox' (change)="handleInvalidCheck()" />
                        <span>Set Run</span>
                    </div>
                </div>
                <div class='flex flex-col items-start px-4'>

                    <div *ngIf="vehicles.length>0" class='flex justify-start items-center gap-x-2'>
                        <input type='checkbox' formControlName='charging_dedi' />
                        <span>DEDI</span>
                    </div>
                    <div *ngIf="vehicles.length>0" class='flex justify-start items-center gap-x-2'>
                        <input type='checkbox' formControlName='charging_flexi' />
                        <span>FLEXI</span>
                    </div>
                    <div *ngIf="vehicles.length>0" class='flex justify-start items-center gap-x-2'>
                        <input type='checkbox' formControlName='charging_setrun' />
                        <span>Set Run</span>
                    </div>
                    <div class='flex flex-col items-start gap-2'>
                        <label>Entity Name for Invoice</label>
                        <input
                            class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px] border-[#D0D5DD]'
                            type='text' formControlName='entity_name' [maxlength]='300' />
                    </div>
                    <div class='flex flex-col items-start gap-2'>
                        <label>Invoice Description Prefix</label>
                        <input
                            class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px] border-[#D0D5DD]'
                            type='text' formControlName='invoice_prefix' [maxlength]='200' />
                    </div>
                    <div class='flex flex-col items-start gap-2'>
                        <label>Payment Terms (days)</label>
                        <input
                            class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                            [ngClass]="{'border-[#CD4244]':isInvalidControl('payment_terms'),
                    'border-[#D0D5DD]':!isInvalidControl('payment_terms')}" currencyMask
                            [options]="{prefix:'',suffix:'',min:0,precision:0}" formControlName='payment_terms' />
                    </div>
                </div>

            </div>
        </div>
    </ng-container>
    <div [ngClass]="{'lg:col-span-3 col-span-12':editMode,'lg:col-span-9 col-span-12 ':!editMode}"
        class='whitespace-nowrap flex flex-col gap-5'>


        <div class='w-full flex flex-col items-start gap-y-5 box-border rounded-md bg-white'
            [ngClass]="{'px-4 py-9':editMode,' p-10':!editMode}">
            <div *ngIf="editMode" class='w-full flex flex-col items-start gap-5'>
                <span class='text-[#101828] text-base font-Inter font-medium leading-6'>Clients</span>
                <div [ngClass]="{'bg-[#F9FAFB]':selected==='details'}"
                    class='w-full flex justify-between items-center box-border px-3 py-2 rounded-md'>
                    <span class='text-[#101828] text-base font-Inter font-semibold leading-6'>Details</span>
                    <span class='hover:cursor-pointer' (click)="handleSidebarCollapse('details')">
                        <img *ngIf="SIDEBAR_COLLAPSE.details" src="/assets/icons/chevron-down.svg" alt="collapse" />
                        <img *ngIf="!SIDEBAR_COLLAPSE.details" src="/assets/icons/chevron-up.svg" alt="collapse" />
                    </span>
                </div>
                <hr class='w-full border-t border-solid border-[#EAECF0]' *ngIf="SIDEBAR_COLLAPSE.details" />
            </div>
            <div #details class='w-full flex flex-col items-start gap-y-5 box-border rounded-md bg-white'
                *ngIf="SIDEBAR_COLLAPSE.details||!editMode">
                <div [ngClass]="{'non-touchable':!authService.hasPermissions(['full:clients','edit:clients',
                'edit-details:clients'])}" class='w-full flex flex-col items-start gap-y-5 box-border'>
                    <div class='flex flex-col items-start gap-y-2 w-full'>
                        <span class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>Business
                            Name:</span>
                        <input
                            class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                            [ngClass]="{'border-[#CD4244]':isInvalidControl('name'),
                    'border-[#D0D5DD]':!isInvalidControl('name'),'non-touchable':editMode}" type='text'
                            formControlName='name' [matAutocomplete]="businessName" />
                        <mat-autocomplete #businessName="matAutocomplete">
                            <mat-option class='text-sm'
                                *ngFor="let department of departments | searchFilter:clientCreationForm.controls.name.value"
                                [value]="department.name">
                                {{department.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <hr class='w-full border-t border-solid border-[#EAECF0]' />
                    <div class='w-full flex flex-col items-start gap-y-2'>
                        <span class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>Point of
                            Contact:</span>
                        <input
                            class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                            [ngClass]="{'border-[#CD4244]':isInvalidControl('point_of_contact'),
                    'border-[#D0D5DD]':!isInvalidControl('point_of_contact')}" type='text'
                            formControlName='point_of_contact' />
                    </div>
                    <div class='w-full flex flex-col items-start gap-y-2'>
                        <div class='w-full flex flex-col items-start gap-y-2'>
                            <span class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>Contact
                                Number:</span>
                            <input
                                class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                [ngClass]="{'border-[#CD4244]':isInvalidControl('contact_number'),
                    'border-[#D0D5DD]':!isInvalidControl('contact_number')}" type='text'
                                formControlName='contact_number' />
                        </div>
                    </div>
                    <div class='w-full flex gap-5'
                        [ngClass]="{'flex-col items-start':editMode,'flex-row justify-start':!editMode}">
                        <div class='w-full flex flex-col items-start gap-y-2' formArrayName='emails'>
                            <div class='w-full flex justify-between items-center'>
                                <span
                                    class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>Email:</span>
                                <span *ngIf="emailsFormArray.length<5" (click)="addExtraEmail()"
                                    class='underline whitespace-nowrap text-[#344054] font-medium leading-5 text-sm hover:cursor-pointer hover:text-[#101828]'>+
                                    Extra Email</span>
                                <span *ngIf="emailsFormArray.length>=5"></span>
                            </div>



                            <div class='w-full flex gap-x-5' *ngFor="
                        let email of emailsFormArray.controls;
                        let i = index
                      ">

                                <div [formGroupName]="i" class='w-full flex justify-start items-center gap-x-5'>
                                    <div class='border border-solid rounded-lg outline-none w-full box-border px-[14px] py-[10px] flex gap-x-2 items-center justify-start'
                                        [ngClass]="{'border-[#CD4244]':i===0&&isControlInValidInGroup(emailsFormArray, i,'email'),
                                    'border-[#D0D5DD]':i!==0||!isControlInValidInGroup(emailsFormArray, i,'email')}">
                                        <img src="/assets/icons/mail.svg" alt="mail" />
                                        <input
                                            class='boder-none outline-none text-[#101828]  text-base font-normal leading-6 w-full'
                                            type='text' formControlName='email' />
                                    </div>
                                    <span (click)="deleteExtraEmail(i)"
                                        class='w-10 h-10 font-extrabold whitespace-nowrap text-[#344054] leading-5 text-sm border border-solid rounded-lg box-border p-2 flex justify-center items-center border-[#D0D5DD] hover:cursor-pointer'
                                        *ngIf="i>0"> ✕</span>
                                </div>
                            </div>





                        </div>
                    </div>
                    <div class='w-full flex justify-start gap-5'
                        [ngClass]="{'flex-col items-start':editMode,'flex-row justify-start':!editMode}">
                        <div class='w-full flex flex-col items-start gap-y-2'>
                            <span class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>Address:</span>
                            <input
                                class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                [ngClass]="{'border-[#CD4244]':isInvalidControl('address'),
                    'border-[#D0D5DD]':!isInvalidControl('address')}" type='text' formControlName='address' />
                        </div>
                        <div class='w-full flex flex-col items-start gap-y-2'>
                            <span class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>Suburb:</span>
                            <input
                                class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                [ngClass]="{'border-[#CD4244]':isInvalidControl('suburb'),
                    'border-[#D0D5DD]':!isInvalidControl('suburb')}" type='text' formControlName='suburb' />
                        </div>
                    </div>
                    <div class='w-full flex justify-start gap-5'
                        [ngClass]="{'flex-col items-start':editMode,'flex-row justify-start':!editMode}">
                        <div class='w-full flex flex-col items-start gap-y-2'>
                            <span
                                class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>Postcode:</span>
                            <input
                                class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                [ngClass]="{'border-[#CD4244]':isInvalidControl('postcode'),
                    'border-[#D0D5DD]':!isInvalidControl('postcode')}" type='text' formControlName='postcode' />
                        </div>
                        <div class='w-full flex flex-col items-start gap-y-2'>
                            <span class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>State:</span>
                            <input
                                class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                [ngClass]="{'border-[#CD4244]':isInvalidControl('state'),
                    'border-[#D0D5DD]':!isInvalidControl('state'),'non-touchable':editMode}" type='text'
                                formControlName='state' [matAutocomplete]="state" />
                            <mat-autocomplete #state="matAutocomplete">
                                <mat-option class='text-sm'
                                    *ngFor="let state of states | searchFilter:clientCreationForm.controls.state.value"
                                    [value]="state.name">
                                    {{state.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                    <div class='w-full flex justify-start gap-5'
                        [ngClass]="{'flex-col items-start':editMode,'flex-row justify-start':!editMode}">
                        <div class='w-full flex flex-col items-start gap-y-2'>
                            <span class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>Depot
                                Lat:</span>
                            <input
                                class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                [ngClass]="{'border-[#CD4244]':isInvalidControl('depot_lat'),
                    'border-[#D0D5DD]':!isInvalidControl('depot_lat')}" type='text' formControlName='depot_lat' />
                        </div>
                        <div class='w-full flex flex-col items-start gap-y-2'>
                            <span class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>Depot
                                Lng:</span>
                            <input
                                class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                [ngClass]="{'border-[#CD4244]':isInvalidControl('depot_lng'),
                    'border-[#D0D5DD]':!isInvalidControl('depot_lng')}" type='text' formControlName='depot_lng' />
                        </div>
                    </div>
                </div>

                <div class='w-full flex gap-5'
                    [ngClass]="{'flex-col items-start':editMode,'flex-row justify-start':!editMode}">
                    <div class='w-full flex flex-col items-start gap-y-2'>
                        <span class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>Cost Account
                            Code:</span>
                        <input
                            class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                            [ngClass]="{'border-[#CD4244]':isInvalidControl('account_code'),
                            'border-[#D0D5DD]':!isInvalidControl('account_code')}" type='text'
                            formControlName='account_code' />
                    </div>
                    <div class='w-full flex flex-col items-start gap-y-2'>
                        <span class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>Invoice
                            Code:</span>
                        <input
                            class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                            [ngClass]="{'border-[#CD4244]':isInvalidControl('invoice_code'),
                            'border-[#D0D5DD]':!isInvalidControl('invoice_code')}" type='text'
                            formControlName='invoice_code' />
                    </div>
                </div>

                <div [ngClass]="{'non-touchable':!authService.hasPermissions(['full:clients','edit:clients',
                'edit-fpod:clients'])}" class='w-full flex justify-start items-center gap-2'>
                    <input type='checkbox' formControlName='forced_pod' />
                    <span class='text-[#101828] text-base font-normal leading-6'>Force Proof of Delivery?</span>
                </div>
                <div [ngClass]="{'non-touchable':!authService.hasPermissions(['full:clients','edit:clients',
                'edit-operations:clients'])}" class='w-full flex flex-col items-start gap-y-2'
                    formArrayName='operation_contacts'>
                    <div class='w-full flex justify-start items-center gap-5'>
                        <span *ngIf="operationContactsFormArray.length<10" (click)="addOperationContact()"
                            class='underline whitespace-nowrap text-[#344054] font-medium leading-5 text-sm hover:cursor-pointer hover:text-[#101828]'>
                            Add Operation Contacts</span>
                    </div>
                    <div class='w-full flex gap-x-5' *ngFor="
                let email of operationContactsFormArray.controls;
                let i = index
              ">

                        <div [formGroupName]="i"
                            class='w-full flex flex-col justify-start items-center gap-x-5 gap-y-2 border border-solid rounded-xl p-4'>
                            <div class='w-full flex items-center justify-end'>
                                <span (click)="deleteOperationContact(i)"
                                    class='w-10 h-10 font-extrabold whitespace-nowrap text-[#344054] leading-5 text-sm border border-solid rounded-lg box-border p-2 flex justify-center items-center border-[#D0D5DD] hover:cursor-pointer'>✕</span>
                            </div>
                            <div class='w-full flex items-center gap-x-5 gap-y-2'
                                [ngClass]="{'flex-col items-start':editMode,'flex-row justify-start':!editMode}">
                                <div class='w-full flex flex-col items-start gap-y-2'>
                                    <span class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>Point
                                        of
                                        Contact:</span>
                                    <input
                                        class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                        [ngClass]="{'border-[#CD4244]':isControlInValidInGroup(operationContactsFormArray, i,'point_of_contact'),
                    'border-[#D0D5DD]':!isControlInValidInGroup(operationContactsFormArray, i,'point_of_contact')}"
                                        type='text' formControlName='point_of_contact' />
                                </div>
                                <div class='w-full flex flex-col items-start gap-y-2'>
                                    <span
                                        class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>Position:</span>
                                    <input
                                        class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px] border-[#D0D5DD]'
                                        type='text' formControlName='position' />
                                </div>
                            </div>
                            <div class='w-full flex items-center gap-x-5 gap-y-2'
                                [ngClass]="{'flex-col items-start':editMode,'flex-row justify-start':!editMode}">
                                <div class='w-full flex flex-col items-start gap-y-2'>
                                    <span class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>Contact
                                        Number:</span>
                                    <input
                                        class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px] border-[#D0D5DD]'
                                        type='text' formControlName='contact_number' />
                                </div>
                                <div class='w-full flex flex-col items-start gap-y-2'>
                                    <span
                                        class='whitespace-nowrap text-[#344054] font-medium leading-5 text-sm'>Email:</span>
                                    <div class='border border-solid rounded-lg outline-none w-full box-border px-[14px] py-[10px] flex gap-x-2 items-center justify-start'
                                        [ngClass]="{'border-[#CD4244]':isControlInValidInGroup(operationContactsFormArray, i,'email'),
                            'border-[#D0D5DD]':!isControlInValidInGroup(operationContactsFormArray, i,'email')}">
                                        <img src="/assets/icons/mail.svg" alt="mail" />
                                        <input
                                            class='boder-none outline-none text-[#101828]  text-base font-normal leading-6 w-full'
                                            type='text' formControlName='email' />
                                    </div>
                                </div>
                            </div>
                            <div class='w-full flex items-center justify-start gap-2'>
                                <input type='checkbox' formControlName='send_booking' />
                                <span class='text-[#101828] text-base font-normal leading-6'>Send booking
                                    information?</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="vehicles.length>0&&editMode">
                <div [ngClass]="{'bg-[#F9FAFB]':selected==='chargingTerms'}"
                    class='w-full flex justify-between items-center box-border px-3 py-2 rounded-md'>
                    <span class='text-[#101828] text-base font-Inter font-semibold leading-6'>Charging Terms</span>
                    <span class='hover:cursor-pointer' (click)="handleSidebarCollapse('chargingTerms')">
                        <img *ngIf="SIDEBAR_COLLAPSE.chargingTerms" src="/assets/icons/chevron-down.svg"
                            alt="collapse" />
                        <img *ngIf="!SIDEBAR_COLLAPSE.chargingTerms" src="/assets/icons/chevron-up.svg"
                            alt="collapse" />
                    </span>
                </div>
                <ng-container *ngIf="SIDEBAR_COLLAPSE.chargingTerms">
                    <div [ngClass]="{'non-touchable':!authService.hasPermissions(['full:clients','edit:clients','edit-terms:clients'])}"
                        class='flex flex-col items-start'>

                        <div class='flex justify-start items-center gap-x-2'>
                            <input type='checkbox' formControlName='charging_dedi' />
                            <span>DEDI</span>
                        </div>
                        <div class='flex justify-start items-center gap-x-2'>
                            <input type='checkbox' formControlName='charging_flexi' />
                            <span>FLEXI</span>
                        </div>
                        <div class='flex justify-start items-center gap-x-2'>
                            <input type='checkbox' formControlName='charging_setrun' />
                            <span>Set Run</span>
                        </div>
                        <div class='flex flex-col items-start gap-2'>
                            <label>Entity Name for Invoice</label>
                            <input
                                class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px] border-[#D0D5DD]'
                                type='text' formControlName='entity_name' [maxlength]='300' />
                        </div>
                        <div class='flex flex-col items-start gap-2'>
                            <label>Invoice Description Prefix</label>
                            <input
                                class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px] border-[#D0D5DD]'
                                type='text' formControlName='invoice_prefix' [maxlength]='200' />
                        </div>
                        <div class='flex flex-col items-start gap-2'>
                            <label>Payment Terms (days)</label>
                            <input
                                class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                [ngClass]="{'border-[#CD4244]':isInvalidControl('payment_terms'),
                    'border-[#D0D5DD]':!isInvalidControl('payment_terms')}" currencyMask
                                [options]="{prefix:'',suffix:'',min:0,precision:0}" formControlName='payment_terms' />
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>

    </div>
    <div *ngIf="!editMode" class='lg:col-span-3 col-span-12'></div>
    <div class='lg:col-span-9 col-span-12 whitespace-nowrap flex flex-col gap-5'>

        <div [ngClass]="{'non-touchable':!authService.hasPermissions(['full:clients','edit:clients','edit-terms:clients'])}"
            #charging_terms class='w-full flex flex-col gap-5' *ngIf="vehicles.length>0">

            <!-- Charging Terms for DEDI -->
            <div class='w-full flex flex-col items-start gap-y-5 box-border p-10 rounded-md bg-white'
                *ngIf="IsSetTerm('charging_dedi')">
                <div class='w-full flex flex-col'>
                    <span class='big_title'>DEDI Terms</span>

                    <hr class='w-full border-t border-solid border-[#EAECF0] my-5' />
                    <div class='flex flex-col items-start gap-y-6' formGroupName='dedi_minimums'>
                        <span class='small_title_black'>Minimums (Pieces)</span>

                        <div class='flex flex-col items-start gap-y-2 w-full'>
                            <span class='small_title_grey'>Sprint</span>

                            <input
                                class='border border-solid rounded-lg outline-none w-[40%] text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                currencyMask [options]="{prefix:'',suffix:'',precision:1}" formControlName="sprint" />
                        </div>
                        <div class='flex flex-col items-start gap-y-2 w-full'>
                            <span class='small_title_grey'>Marathon</span>

                            <input
                                class='border border-solid rounded-lg outline-none w-[40%] text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                currencyMask [options]="{prefix:'',suffix:'',precision:1}" formControlName="marathon" />
                        </div>
                    </div>

                    <hr class='w-full border-t border-solid border-[#EAECF0] my-8' />
                    <div class='w-full flex flex-col items-start box-border' formArrayName='dedi_terms'>

                        <div class='w-full flex flex-col gap-3' *ngFor="
                let term of CHARGING_TERMS;
                let i = index
              ">
                            <div *ngIf="term!=='_weekend_loading'"
                                class='w-[40%] flex justify-between items-center mt-3'>
                                <label class='medium_title_grey'>{{GetLabel(term)}}</label>
                                <span class='hover:cursor-pointer' (click)="handleCollapse('dedi',term)">
                                    <img *ngIf="IsCollapsed('dedi',term)" src="/assets/icons/chevron-down.svg"
                                        alt="collapse" />
                                    <img *ngIf="!IsCollapsed('dedi',term)" src="/assets/icons/chevron-up.svg"
                                        alt="collapse" />
                                </span>
                            </div>
                            <div *ngIf="!IsCollapsed('dedi',term==='_weekend_loading'?'weekend_loading':term)"
                                [formGroupName]="i" class='w-[60%] flex flex-col gap-4'>

                                <div class='flex flex-col items-start gap-2'>
                                    <span *ngIf="term==='weekend_loading'"
                                        class='w-full text-[#344054] text-base font-semibold leading-6'>Saturday</span>
                                    <span *ngIf="term==='_weekend_loading'"
                                        class='w-full text-[#344054] text-base font-semibold leading-6'>Sunday</span>
                                    <div class='flex justify-start gap-2'>
                                        <div class='flex flex-col items-start gap-y-2 w-full'>
                                            <div class='small_title_grey'>
                                                Percentage
                                            </div>
                                            <div class=' flex items-center w-full'>
                                                <input class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]
                                        border-[#D0D5DD]' currencyMask [options]="{prefix:'',suffix:'%',min:0,max:100}"
                                                    formControlName="percentage" />
                                            </div>
                                        </div>

                                        <div class='flex flex-col items-start gap-y-2 w-full'>
                                            <div class='small_title_grey'>
                                                Fixed Rate
                                            </div>
                                            <div class=' flex items-center w-full'>
                                                <input class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]
                                        border-[#D0D5DD]' currencyMask [options]="{prefix:'$'}"
                                                    formControlName="fixed_rate" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='flex justify-start gap-2'
                                    *ngIf="term=='after_hours'&&IsViewable('dediTermsFormArray')">
                                    <div class='flex flex-col items-start gap-y-2 w-full'>
                                        <div class='small_title_grey'>
                                            From
                                        </div>
                                        <div class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]
                                        border-[#D0D5DD]'>
                                            <ngx-timepicker-field [controlOnly]="true"
                                                [(ngModel)]="AFTER_HOURS.dediTermsFormArray.from"
                                                (ngModelChange)="handleFromTimeChange('dediTermsFormArray',$event)"
                                                [ngModelOptions]="{standalone: true}"></ngx-timepicker-field>
                                        </div>
                                    </div>
                                    <input class='hidden' formControlName="from" />
                                    <div class='flex flex-col items-start gap-y-2 w-full'>
                                        <div class='small_title_grey'>
                                            Until
                                        </div>
                                        <div class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]
                                        border-[#D0D5DD]'>
                                            <ngx-timepicker-field [controlOnly]="true"
                                                [(ngModel)]="AFTER_HOURS.dediTermsFormArray.to"
                                                (ngModelChange)="handleToTimeChange('dediTermsFormArray',$event)"
                                                [ngModelOptions]="{standalone: true}"></ngx-timepicker-field>
                                        </div>
                                    </div>
                                    <input formControlName="to" class='hidden' />
                                </div>

                            </div>
                        </div>
                    </div>
                    <hr class='w-full border-t border-solid border-[#EAECF0] mb-8 mt-16' />
                    <div class='w-full flex flex-col items-start gap-y-5'>
                        <span class='big_title'>Products</span>
                        <div class='w-full flex flex-col items-start gap-y-2' formArrayName='dedi_product'>
                            <div class='w-full flex gap-x-5'>
                                <span class='small_title_black w-[50%] justify-start'>SERVICE</span>
                                <div class='w-[50%] flex gap-x-5'>
                                    <span class='small_title_black w-[40%] justify-start'>RATE</span>
                                    <span class='small_title_black w-[60%] justify-start'>FUEL SURCHARGE</span>
                                </div>
                            </div>
                            <div class='w-full flex gap-x-5' *ngFor="
                let vehicle of dediProductFormArray.controls;
                let i = index
              ">
                                <div class='w-[50%] flex justify-start items-center'>
                                    <label class='medium_title_grey'>{{ vehicle.get('role')?.value }}</label>
                                </div>
                                <div [formGroupName]="i" class='w-[50%] flex justify-start items-center gap-x-5'>
                                    <div class=' w-[40%] justify-start'>
                                        <input
                                            class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                            [ngClass]="{'border-[#CD4244]':isControlInValidInGroup(dediProductFormArray, i,'rate'),
                            'border-[#D0D5DD]':!isControlInValidInGroup(dediProductFormArray, i,'rate')}" currencyMask
                                            [options]="{prefix:'$'}" formControlName="rate" />
                                    </div>
                                    <div class=' w-[60%] justify-start'>
                                        <input
                                            class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                            [ngClass]="{'border-[#CD4244]':isControlInValidInGroup(dediProductFormArray, i,'fuel'),
                            'border-[#D0D5DD]':!isControlInValidInGroup(dediProductFormArray, i,'fuel')}" currencyMask
                                            [options]="{prefix:'',suffix:'%',min:0,max:100}" formControlName="fuel" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class='w-full border-t border-solid border-[#EAECF0] mb-8 mt-16' />
                    <div class='flex flex-col items-start gap-y-6'>
                        <div class='flex flex-col items-start gap-y-2 w-full'>
                            <span class='title_black'>Notes:</span>
                            <textarea rows="5"
                                class='w-full border border-[#D0D5DD] border-solid text-base font-Inter px-[14px] py-[10px] rounded-lg outline-none scroll-thin-style'
                                formControlName='dedi_notes'></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Charging Terms for FLEXI -->
            <div class='w-full flex flex-col items-start gap-y-5 box-border p-10 rounded-md bg-white'
                *ngIf="IsSetTerm('charging_flexi')">
                <div class='w-full flex flex-col'>
                    <span class='big_title'>FLEXI Terms</span>

                    <hr class='w-full border-t border-solid border-[#EAECF0] my-5' />
                    <div class='flex flex-col items-start gap-y-6' formGroupName='flexi_minimums'>
                        <span class='small_title_black'>Minimums (Pieces)</span>

                        <div class='flex flex-col items-start gap-y-2 w-full'>
                            <span class='small_title_grey'>FLEXI 40</span>

                            <input
                                class='border border-solid rounded-lg outline-none w-[40%] text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                currencyMask [options]="{prefix:'',suffix:'',precision:0}" formControlName="sprint" />
                        </div>
                        <div class='flex flex-col items-start gap-y-2 w-full'>
                            <span class='small_title_grey'>FLEXI 60</span>

                            <input
                                class='border border-solid rounded-lg outline-none w-[40%] text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                currencyMask [options]="{prefix:'',suffix:'',precision:0}" formControlName="marathon" />
                        </div>
                    </div>
                    <hr class='w-full border-t border-solid border-[#EAECF0] my-8' />
                    <div class='w-full flex flex-col items-start box-border' formArrayName='flexi_terms'>

                        <div class='w-full flex flex-col gap-3' *ngFor="
                let term of CHARGING_TERMS;
                let i = index
              ">
                            <div *ngIf="term!=='_weekend_loading'"
                                class='w-[40%] flex justify-between items-center mt-3'>
                                <label class='medium_title_grey'>{{GetLabel(term)}}</label>
                                <span class='hover:cursor-pointer' (click)="handleCollapse('flexi',term)">
                                    <img *ngIf="IsCollapsed('flexi',term)" src="/assets/icons/chevron-down.svg"
                                        alt="collapse" />
                                    <img *ngIf="!IsCollapsed('flexi',term)" src="/assets/icons/chevron-up.svg"
                                        alt="collapse" />
                                </span>
                            </div>
                            <div *ngIf="!IsCollapsed('flexi',term==='_weekend_loading'?'weekend_loading':term)"
                                [formGroupName]="i" class='w-[60%] flex flex-col gap-4'>
                                <div class='flex flex-col items-start gap-2'>
                                    <span *ngIf="term==='weekend_loading'"
                                        class='w-full text-[#344054] text-base font-semibold leading-6'>Saturday</span>
                                    <span *ngIf="term==='_weekend_loading'"
                                        class='w-full text-[#344054] text-base font-semibold leading-6'>Sunday</span>
                                    <div class='flex justify-start gap-2'>
                                        <div class='flex flex-col items-start gap-y-2 w-full'>
                                            <div class='small_title_grey'>
                                                Percentage
                                            </div>
                                            <div class=' flex items-center w-full'>
                                                <input class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]
                                        border-[#D0D5DD]' currencyMask [options]="{prefix:'',suffix:'%',min:0,max:100}"
                                                    formControlName="percentage" />
                                            </div>
                                        </div>

                                        <div class='flex flex-col items-start gap-y-2 w-full'>
                                            <div class='small_title_grey'>
                                                Fixed Rate
                                            </div>
                                            <div class=' flex items-center w-full'>
                                                <input class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]
                                        border-[#D0D5DD]' currencyMask [options]="{prefix:'$'}"
                                                    formControlName="fixed_rate" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='flex justify-start gap-2'
                                    *ngIf="term=='after_hours'&&IsViewable('flexiTermsFormArray')">
                                    <div class='flex flex-col items-start gap-y-2 w-full'>
                                        <div class='small_title_grey'>
                                            From
                                        </div>
                                        <div class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]
                                        border-[#D0D5DD]'>
                                            <ngx-timepicker-field [controlOnly]="true"
                                                [(ngModel)]="AFTER_HOURS.flexiTermsFormArray.from"
                                                (ngModelChange)="handleFromTimeChange('flexiTermsFormArray',$event)"
                                                [ngModelOptions]="{standalone: true}"></ngx-timepicker-field>
                                        </div>
                                    </div>
                                    <input class='hidden' formControlName="from" />
                                    <div class='flex flex-col items-start gap-y-2 w-full'>
                                        <div class='small_title_grey'>
                                            Until
                                        </div>
                                        <div class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]
                                        border-[#D0D5DD]'>
                                            <ngx-timepicker-field [controlOnly]="true"
                                                [(ngModel)]="AFTER_HOURS.flexiTermsFormArray.to"
                                                (ngModelChange)="handleToTimeChange('flexiTermsFormArray',$event)"
                                                [ngModelOptions]="{standalone: true}"></ngx-timepicker-field>
                                        </div>
                                    </div>
                                    <input formControlName="to" class='hidden' />
                                </div>

                            </div>
                        </div>
                    </div>
                    <hr class='w-full border-t border-solid border-[#EAECF0] mb-8 mt-16' />

                    <div class='w-full flex flex-col items-start gap-y-5'>
                        <span class='big_title'>Products</span>
                        <div class='w-full flex flex-col items-start gap-y-2' formArrayName='flexi_product'>
                            <div class='w-full flex gap-x-5'>
                                <span class='small_title_black w-[40%] justify-start'>Service</span>
                                <div class='w-[60%] flex gap-x-5'>
                                    <span class='small_title_black w-[20%] justify-start'>Delivery
                                        Rate</span>
                                    <span class='small_title_black w-[20%] justify-start'>Pickup
                                        Rate</span>
                                    <span class='small_title_black w-[60%] justify-start'>Fuel
                                        Surcharge</span>
                                </div>
                            </div>
                            <div class='w-full flex gap-x-5' *ngFor="
                let vehicle of flexiProductFormArray.controls;
                let i = index
              ">
                                <div class='w-[40%] flex justify-start items-center'>
                                    <label>{{ vehicle.get('role')?.value }}</label>
                                </div>
                                <div [formGroupName]="i" class='w-[60%] flex justify-start items-center gap-x-5'>
                                    <div class=' w-[20%] justify-start'>
                                        <input
                                            class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                            [ngClass]="{'border-[#CD4244]':isControlInValidInGroup(flexiProductFormArray, i,'rate'),
                            'border-[#D0D5DD]':!isControlInValidInGroup(flexiProductFormArray, i,'rate')}" currencyMask
                                            [options]="{prefix:'$'}" formControlName="rate" />
                                    </div>
                                    <div class=' w-[20%] justify-start'>
                                        <input
                                            class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                            [ngClass]="{'border-[#CD4244]':isControlInValidInGroup(flexiProductFormArray, i,'rate2'),
                            'border-[#D0D5DD]':!isControlInValidInGroup(flexiProductFormArray, i,'rate2')}"
                                            currencyMask [options]="{prefix:'$'}" formControlName="rate2" />
                                    </div>
                                    <div class=' w-[60%] justify-start'>
                                        <input
                                            class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                            [ngClass]="{'border-[#CD4244]':isControlInValidInGroup(flexiProductFormArray, i,'fuel'),
                            'border-[#D0D5DD]':!isControlInValidInGroup(flexiProductFormArray, i,'fuel')}" currencyMask
                                            [options]="{prefix:'',suffix:'%',min:0,max:100}" formControlName="fuel" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class='w-full border-t border-solid border-[#EAECF0] mb-8 mt-16' />
                    <div class='flex flex-col items-start gap-y-6'>
                        <div class='flex flex-col items-start gap-y-2 w-full'>
                            <span class='title_black'>Notes:</span>
                            <textarea rows="5"
                                class='w-full border border-[#D0D5DD] border-solid text-base font-Inter px-[14px] py-[10px] rounded-lg outline-none scroll-thin-style'
                                formControlName='flexi_notes'></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Charging Terms for SET RUN -->
            <div class='w-full flex flex-col items-start gap-y-5 box-border p-10 rounded-md bg-white'
                *ngIf="IsSetTerm('charging_setrun')">
                <div class='w-full flex flex-col'>
                    <span class='big_title'>Set Run Terms</span>

                    <hr class='w-full border-t border-solid border-[#EAECF0] my-5' />
                    <div class='w-full flex flex-col items-start box-border' formArrayName='setRun_terms'>

                        <div class='w-full flex flex-col gap-3' *ngFor="
                let term of CHARGING_TERMS;
                let i = index
              ">
                            <div *ngIf="term!=='_weekend_loading'"
                                class='w-[40%] flex justify-between items-center mt-3'>
                                <label class='medium_title_grey'>{{GetLabel(term)}}</label>
                                <span class='hover:cursor-pointer' (click)="handleCollapse('setRun',term)">
                                    <img *ngIf="IsCollapsed('setRun',term)" src="/assets/icons/chevron-down.svg"
                                        alt="collapse" />
                                    <img *ngIf="!IsCollapsed('setRun',term)" src="/assets/icons/chevron-up.svg"
                                        alt="collapse" />
                                </span>
                            </div>
                            <div *ngIf="!IsCollapsed('setRun',term==='_weekend_loading'?'weekend_loading':term)"
                                [formGroupName]="i" class='w-[60%] flex flex-col gap-4'>
                                <div class='flex flex-col items-start gap-2'>
                                    <span *ngIf="term==='weekend_loading'"
                                        class='w-full text-[#344054] text-base font-semibold leading-6'>Saturday</span>
                                    <span *ngIf="term==='_weekend_loading'"
                                        class='w-full text-[#344054] text-base font-semibold leading-6'>Sunday</span>
                                    <div class='flex justify-start gap-2'>
                                        <div class='flex flex-col items-start gap-y-2 w-full'>
                                            <div class='small_title_grey'>
                                                Percentage
                                            </div>
                                            <div class=' flex items-center w-full'>
                                                <input class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]
                                        border-[#D0D5DD]' currencyMask [options]="{prefix:'',suffix:'%',min:0,max:100}"
                                                    formControlName="percentage" />
                                            </div>
                                        </div>

                                        <div class='flex flex-col items-start gap-y-2 w-full'>
                                            <div class='small_title_grey'>
                                                Fixed Rate
                                            </div>
                                            <div class=' flex items-center w-full'>
                                                <input class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]
                                        border-[#D0D5DD]' currencyMask [options]="{prefix:'$'}"
                                                    formControlName="fixed_rate" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='flex justify-start gap-2'
                                    *ngIf="term=='after_hours'&&IsViewable('setRunTermsFormArray')">
                                    <div class='flex flex-col items-start gap-y-2 w-full'>
                                        <div class='small_title_grey'>
                                            From
                                        </div>
                                        <div class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]
                                        border-[#D0D5DD]'>
                                            <ngx-timepicker-field [controlOnly]="true"
                                                [(ngModel)]="AFTER_HOURS.setRunTermsFormArray.from"
                                                (ngModelChange)="handleFromTimeChange('setRunTermsFormArray',$event)"
                                                [ngModelOptions]="{standalone: true}"></ngx-timepicker-field>
                                        </div>
                                    </div>
                                    <input class='hidden' formControlName="from" />
                                    <div class='flex flex-col items-start gap-y-2 w-full'>
                                        <div class='small_title_grey'>
                                            Until
                                        </div>
                                        <div class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]
                                        border-[#D0D5DD]'>
                                            <ngx-timepicker-field [controlOnly]="true"
                                                [(ngModel)]="AFTER_HOURS.setRunTermsFormArray.to"
                                                (ngModelChange)="handleToTimeChange('setRunTermsFormArray',$event)"
                                                [ngModelOptions]="{standalone: true}"></ngx-timepicker-field>
                                        </div>
                                    </div>
                                    <input formControlName="to" class='hidden' />
                                </div>

                            </div>
                        </div>
                    </div>
                    <hr class='w-full border-t border-solid border-[#EAECF0] mb-8 mt-16' />

                    <div class='w-full flex flex-col items-start gap-y-5'>
                        <span class='big_title'>Products</span>
                        <div class='w-full flex flex-col items-start gap-y-2' formArrayName='setRun_product'>
                            <div class='w-full flex gap-x-5'>
                                <span class='small_title_black w-[50%] justify-start'>SERVICE</span>
                                <div class='w-[50%] flex gap-x-5'>
                                    <span class='small_title_black w-[40%] justify-start'>RATE</span>
                                    <span class='small_title_black w-[60%] justify-start'>FUEL SURCHARGE</span>
                                </div>
                            </div>
                            <div class='w-full flex gap-x-5' *ngFor="
                let vehicle of setRunProductFormArray.controls;
                let i = index
              ">
                                <div class='w-[50%] flex justify-start items-center'>
                                    <label class='medium_title_grey'>{{ vehicle.get('role')?.value }}</label>
                                </div>
                                <div [formGroupName]="i" class='w-[50%] flex justify-start items-center gap-x-5'>
                                    <div class=' w-[40%] justify-start'>
                                        <input
                                            class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                            [ngClass]="{'border-[#CD4244]':isControlInValidInGroup(setRunProductFormArray, i,'rate'),
                            'border-[#D0D5DD]':!isControlInValidInGroup(setRunProductFormArray, i,'rate')}"
                                            currencyMask [options]="{prefix:'$'}" formControlName="rate" />
                                    </div>
                                    <div class=' w-[60%] justify-start'>
                                        <input
                                            class='border border-solid rounded-lg outline-none w-full text-[#101828] text-base font-normal leading-6 box-border px-[14px] py-[10px]'
                                            [ngClass]="{'border-[#CD4244]':isControlInValidInGroup(setRunProductFormArray, i,'fuel'),
                            'border-[#D0D5DD]':!isControlInValidInGroup(setRunProductFormArray, i,'fuel')}"
                                            currencyMask [options]="{prefix:'',suffix:'%',min:0,max:100}"
                                            formControlName="fuel" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class='w-full border-t border-solid border-[#EAECF0] mb-8 mt-16' />
                    <div class='flex flex-col items-start gap-y-6'>
                        <div class='flex flex-col items-start gap-y-2 w-full'>
                            <span class='title_black'>Notes:</span>
                            <textarea rows="5"
                                class='w-full border border-[#D0D5DD] border-solid text-base font-Inter px-[14px] py-[10px] rounded-lg outline-none scroll-thin-style'
                                formControlName='setrun_notes'></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class='w-full flex justify-end'>
            <div class='w-fit flex items-center gap-2'>
                <a class='custom-outlined-btn' routerLink="/clients">Cancel</a>
                <button class='custom-solid-btn' type='submit'>Save</button>
            </div>
        </div>
    </div>
</form>