import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CodedResponseModel } from '../model/CodedResponseModel';
import { buildParams, IndexQuery } from '../model/IndexQuery';
import { User } from '../model/User';
import { apiUrls } from '../settings/settings';
import { EpEncryptionService } from './ep-encryption.service';

@Injectable({
  providedIn: 'root',
})
export class UsersAPIService {
  constructor(
    private http: HttpClient,
    private epEncryptionService: EpEncryptionService
  ) {}

  public index(
    query: IndexQuery,
    orderArr: string[]
  ): Observable<CodedResponseModel> {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}users${buildParams(query)}`,
      this.epEncryptionService.sendPayload({
        orderArr,
      })
    );
  }
  public show(id: number): Observable<CodedResponseModel> {
    return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}user/${id}`);
  }
  public edit(data: User): Observable<CodedResponseModel> {
    return this.http.put<CodedResponseModel>(
      `${apiUrls.apiUrl}user/${data.id}`,
      this.epEncryptionService.sendPayload(data)
    );
  }
  public delete(id: number): Observable<CodedResponseModel> {
    return this.http.delete<CodedResponseModel>(`${apiUrls.apiUrl}user/${id}`);
  }
  public create(data: User): Observable<CodedResponseModel> {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}user`,
      this.epEncryptionService.sendPayload(data)
    );
  }

  public getCurrUser(): Observable<CodedResponseModel> {
    return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}me`);
  }
  public updateCurrUser(data: User): Observable<CodedResponseModel> {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}me`,
      this.epEncryptionService.sendPayload(data)
    );
  }
  public uploadAvatar(file: File): Observable<CodedResponseModel> {
    let data = new FormData();
    data.append('avatar', file);
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}set_avatar`,
      data
    );
  }
  public sendNotifs(id: number) {
    return this.http.post<CodedResponseModel>(
      `${apiUrls.apiUrl}user/${id}/notifs`,
      null
    );
  }

  public getLocations(): Observable<CodedResponseModel> {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/mapping-locations${buildParams({
        page: 1,
        perPage: 1000,
        filters: {
          search: '',
        },
      })}`
    );
  }

  public getDepartments(): Observable<CodedResponseModel> {
    return this.http.get<CodedResponseModel>(
      `${apiUrls.apiUrl}settings/mapping-departments${buildParams({
        page: 1,
        perPage: 1000,
        filters: {
          search: '',
        },
      })}`
    );
  }

  public getPayrates(): Observable<CodedResponseModel> {
    return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}payrates`);
  }

  public getLDR(): Observable<CodedResponseModel> {
    return this.http.get<CodedResponseModel>(`${apiUrls.apiUrl}ldr`);
  }

  public upsertOverriddenPayrates(data: any) {
    return this.http.put<CodedResponseModel>(
      `${apiUrls.apiUrl}payrates/overridden`,
      this.epEncryptionService.sendPayload(data)
    );
  }
}
